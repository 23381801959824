.cFooterWrapper{
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 1rem;
}


.cFooterWrapper>hr{
    width: 80rem;
    height: 1px;
    border: none;
    background: white;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.cFooter{
    display: flex;
    width: 100%;
    justify-content:space-around;

}

.logo{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.logo>img{
    width: 2rem;
    height: 2rem;
}

.logo>span{
    font-weight: 600;
    font-size: 1rem;
}

.block{
     display: flex;
     flex-direction: column;
     gap: 1rem;
     min-width: 12rem;
}
.detail{
  display: flex;
  flex-direction: column;
  width: inherit;
  font-size: 0.7rem;
  gap: 1rem;
  font-style: italic;
}

details>span:nth-of-type(1){
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
}

.pngLine:hover{
    cursor: pointer;

}

.pngLine{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.icon{
    width: 25px;
}

.detail>:nth-child(1){
    font-weight: 500;
    font-size: 1rem;
    font-style: normal;
}

.copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: .65rem;
    text-align: center;
    margin-top: 2rem;
    width: 100%;
}

@media screen and (max-width:768px) {
.cFooter {
   flex-wrap: wrap;
   align-items: flex-start;
   justify-content: flex-start;
   font-size:  1.7rem;
   gap: 2rem;

}
}



@media screen and (max-width:640px) {

    .cFooter{
justify-content: center;
    }

    .logo{
        display: none;
    }

}